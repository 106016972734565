<template>
  <div class="appointmentParkReport content">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >预约报表，按车场维度统计各渠道预约停车情况；适用于运营人员和财务人员了解各个车场预约停车情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./Report/img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="appointShow">
      <el-row :gutter="16">
        <el-col :span="6" v-for="item in reportList" :key="item.id">
          <div class="reportItem">
            <div class="title">
              <div>{{ item.title }}</div>
              <div>
                <el-tooltip class="item" effect="dark" :content="item.hintText" placement="top">
                  <!-- <div style="cursor: pointer;color: #ccc">?</div> -->
                  <img src="./img/tip.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div class="row">
              <div class="sum">{{ item.totalMoney / 100 }}</div>
              <img :width="item.wdith" :height="item.height" :src="item.src" alt="" />
              <!-- <div class="icon-span" :style="{background: `url(${item.src}) no-repeat center/${item.id == 4 ? '58px 58px' : '133px 70px'}`}"></div> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="searchWrapper">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.park_name')">
              <!-- <el-input v-model.trim="formInline.parkName" placeholder="请输入停车场名称"></el-input> -->
              <my-component
                ref="parkInput"
                :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                @valueChange="completeValue"
                slaveRelations="0,1"
              />
            </el-form-item>

            <!-- <el-form-item :label="$t('searchModule.date')">
          <el-date-picker v-model="searchDate"
                          type="daterange"
                          unlink-panels
                          :editable=false
                          :clearable=false
                          value-format='yyyy-MM-dd'
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item> -->

            <el-form-item :label="$t('searchModule.Date_search')" label-width="90px">
              <a-date-picker
                ref="datePicker"
                :YearShowHidden="selectkeys[3]"
                :selectkeys="selectkeys"
                :previousDate="previousDate"
                :nextDate="nextDate"
              ></a-date-picker>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              >{{ $t('button.search') }}</el-button
            >
            <el-button icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button type="" @click="exportFile"><i class="el-icon-upload2"></i>{{ $t('button.export') }}</el-button>
          </div>
        </div>
      </el-form>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <!-- 筛选按钮 -->
        <div class="switch">
          <screen
            :checkBoxGroup="colData"
            :checkedColumns="checkedColumns"
            :reportType="4"
            @selectChange="selectChange"
          ></screen>
        </div>
        <el-table
          header-cell-class-name="headerCallStyle"
          :cell-style="callStyle"
          border
          :key="reload"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            label=""
            v-if="colData[0].isTrue || colData[1].isTrue"
            min-width="150"
            class-name="headerCellQ cell-wrapper headerSize"
          >
            <el-table-column
              v-if="colData[0].isTrue"
              prop="parkName"
              align="center"
              label="车场"
              min-width="150"
              class-name="title-wrapper headerSize"
            ></el-table-column>
            <el-table-column
              v-if="colData[1].isTrue"
              prop="appointmentNum"
              align="center"
              label="预约数"
              min-width="150"
              class-name="title-wrapper"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            label="预约支付金额"
            min-width="150"
            class-name="headerCellQ headerSize"
            v-if="colData[2].isTrue || colData[3].isTrue || colData[4].isTrue || colData[5].isTrue"
          >
            <el-table-column
              v-if="colData[2].isTrue"
              align="center"
              prop="wechatPayMoney"
              label="微信"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.wechatPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[3].isTrue"
              align="center"
              prop="alipayPayMoney"
              label="支付宝"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.alipayPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[4].isTrue"
              align="center"
              prop="cmbPayMoney"
              label="招商银行"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.cmbPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[5].isTrue"
              align="center"
              prop="totalPayMoney"
              label="小计"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.totalPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            label="预约退款金额"
            min-width="150"
            class-name="headerCellW headerSize headerCellQ"
            v-if="colData[6].isTrue || colData[7].isTrue || colData[8].isTrue || colData[9].isTrue"
          >
            <el-table-column
              v-if="colData[6].isTrue"
              align="center"
              prop="wechatRefundMoney"
              label="微信"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.wechatRefundMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[7].isTrue"
              align="center"
              prop="alipayRefundMoney"
              label="支付宝"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.alipayRefundMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[8].isTrue"
              align="center"
              prop="cmbRefundMoney"
              label="招商银行"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.cmbRefundMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[9].isTrue"
              align="center"
              prop="totalRefundMoney"
              label="小计"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.totalRefundMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            label="小计"
            min-width="150"
            class-name="headerCellE headerSize"
            v-if="
              colData[10].isTrue || colData[11].isTrue || colData[12].isTrue || colData[13].isTrue
            "
          >
            <el-table-column
              v-if="colData[10].isTrue"
              align="center"
              prop="wechatTotalMoney"
              label="微信"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.wechatTotalMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[11].isTrue"
              align="center"
              prop="alipayTotalMoney"
              label="支付宝"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.alipayTotalMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[12].isTrue"
              align="center"
              prop="cmbTotalMoney"
              label="招商银行"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.cmbTotalMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[13].isTrue"
              align="center"
              prop="totalMoney"
              label="小计"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.totalMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper1 bgFFF" v-if="total > 0">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            background
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, dateFormat } from "@/common/js/public";
import myComponent from "@/components/autocomplete/myautoComponent";
import screen from "@/components/screen/index";
export default {
  name: "appointmentParkReport",
  components: {
    myComponent,
    screen
  },
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    return {
      closeShow: true,
      screeningNum: 0,
      reload: true,
      previousDate: new Date(),
      nextDate: new Date(),
      // colData中列出表格中的每一列，默认都展示
      colData: [
        { label: "车场", isTrue: false },
        { label: "预约数", isTrue: false },
        { label: "预约支付金额-微信", isTrue: false },
        { label: "预约支付金额-支付宝", isTrue: false },
        { label: "预约支付金额-招商银行", isTrue: false },
        { label: "预约支付金额-小计", isTrue: false },
        { label: "预约退款金额-微信", isTrue: false },
        { label: "预约退款金额-支付宝", isTrue: false },
        { label: "预约退款金额-招商银行", isTrue: false },
        { label: "预约退款金额-小计", isTrue: false },
        { label: "小计-微信", isTrue: false },
        { label: "小计-支付宝", isTrue: false },
        { label: "小计-招商银行", isTrue: false },
        { label: "小计-小计", isTrue: false },
      ],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      selectkeys: ["date", "month", "week", "year"],
      tableDataTotal: [],
      searchDate: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      total: 0,
      page: 1,
      pageSize: 15,
      payType: [],
      formInline: {
        startTime: dateFormat(start, "yyyy-MM-dd"),
        endTime: dateFormat(end, "yyyy-MM-dd"),
        parkId: "",
      },
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime() - 3600 * 1000 * 24;
        },
      },
      reportList: [
        {
          title: "预约总额（元）",
          totalMoney: 0,
          key: "totalMoney",
          color: "#CCFFEE",
          id: 1,
          width: 133,
          height: 70,
          src: require("../../assets/img/appoint1.png"),
          hintText: "截止到昨日，所有预约订单的实付金额之和",
        },
        {
          title: "进行中金额（元）",
          totalMoney: 0,
          key: "ongoingMoney",
          color: "#EEFFCC",
          id: 2,
          width: 133,
          height: 70,
          src: require("../../assets/img/pendding.png"),
          hintText: "截止到昨日，所有进行中的订单实付金额之和",
        },
        {
          title: "退款总额（元）",
          totalMoney: 0,
          key: "refundMoney",
          color: "#FFCCEE",
          id: 3,
          width: 133,
          height: 70,
          src: require("../../assets/img/back.png"),
          hintText: "截止到昨日，所有已完成+已取消的订单退款金额之和",
        },
        {
          title: "已确认收入（元）",
          totalMoney: 0,
          key: "finishMoney",
          color: "#FFEECC",
          id: 4,
          width: 58,
          height: 58,
          src: require("./img/amount.png"),
          hintText: "截止到昨日，预约总额-进行中金额-退款总额",
        },
      ],
    };
  },
  watch: {},
  methods: {
    selectChange(selectList) {
      this.colData.filter((i, index) => {
        i.isTrue = false;
      });
      selectList.forEach((i) => {
        this.colData[i].isTrue = true;
      });
      this.searchData();
    },
    // 获取筛选项
    getReportFieldHide() {
      // 报表类型，1=临停报表；2=运营报表；3=停车卡报表；4=预约报表；5=营收报表；6=渠道统计；7=PDA统计
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: "4",
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.colData[i].isTrue = true
          });
        });
    },
    // table 单元格样式动态设置
    callStyle(row) {
      return {
        "border-right": 0,
      };
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        // this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        // this.formInline.parkName = '';
      }
    },
    resetForm() {
      const start = new Date();
      const end = new Date();
      console.log("sss", start);
      start.setTime(start.getTime() - 24 * 60 * 60 * 1000);
      end.setTime(end.getTime() - 24 * 60 * 60 * 1000);
      this.searchDate = [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")];
      this.formInline = {
        startTime: dateFormat(start, "yyyy-MM-dd"),
        endTime: dateFormat(end, "yyyy-MM-dd"),
        parkId: "",
      };
      this.$refs.datePicker.clear();
      this.$refs.parkInput.clearData();
    },
    // 累计查询
    getSum() {
      this.$axios.get("/acb/2.0/parkAppointmentStatics/getNewestRecord", {}).then((res) => {
        if (res.state === 0 && res.value) {
          for (let obj of this.reportList) {
            for (let key in res.value) {
              if (key === obj.key) {
                obj.totalMoney = res.value[key];
              }
            }
          }
        }
      });
    },
    getNowFormatDate() {
      let date = new Date();
      /* let seperator1 = "-";
      let seperator2 = ":"; */
      let seperator1 = "";
      let seperator2 = "";
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let hours = date.getHours();
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      let minutes = date.getMinutes();
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      let currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        "" +
        hours +
        seperator2 +
        minutes +
        seperator2 +
        seconds;
      return currentdate;
    },
    // 分页器跳转指定页
    handleCurrentChange(val) {
      this.page = val;
      // this.computedPage()
      this.searchData();
    },
    exportFile() {
      let name = "预约停车报表" + this.getNowFormatDate();
      let data = {
        // startTime: this.searchDate[0],
        // endTime: this.searchDate[1],
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        fileName: name,
        parkId: this.formInline.parkId,
      };
      exportExcelNew("/acb/2.0/parkAppointmentStatics/exportlist", data, "post");
    },
    searchData() {
      // this.formInline.startTime = this.searchDate[0]
      // this.formInline.endTime = this.searchDate[1]
      this.formInline.startTime = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      this.formInline.endTime = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      this.formInline.page = this.page;
      this.formInline.pageSize = this.pageSize;
      this.formInline.reportType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      this.$axios
        .get("/acb/2.0/parkAppointmentStatics/list", {
          data: this.formInline,
        })
        .then((res) => {
          this.tableData = res.value.list;
          // this.computedPage()
          // this.total = this.tableDataTotal.length * 1 || 0
          this.total = res.value.total - 0;
        })
        .catch((err) => {
          this.tableData = [];
          this.total = 0;
        });
    },
    computedPage() {
      let pageNum = this.page;
      let pageSize = this.pageSize;
      let tableData = [];
      let len =
        pageNum * pageSize >= this.tableDataTotal.length
          ? this.tableDataTotal.length
          : pageNum * pageSize;
      let currentNum = pageNum > 1 ? pageSize * (pageNum - 1) : 0;
      for (let i = currentNum; i < len; i++) {
        tableData.push(this.tableDataTotal[i]);
      }
      this.tableData = tableData;
    },
    handleClick(value, value2) {},
  },
  created() {
    this.getSum();
  },
  mounted() {
    this.searchData();
    this.getReportFieldHide();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.info
  margin: 0 -20px 10px -20px;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);
  .info_content
    background: rgba(254, 242, 213, 1);
    color: rgba(255, 151, 74, 1);
  img
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
.appointShow
  .reportItem
    height: 126px;
    background: #FFFFFF;
    text-align center
    padding 10px 29px
    border-radius: 4px;
    box-sizing border-box
    .title
      font-size: 14px;
      display flex
      justify-content space-start;
      align-items center
      height 36px
      .icon-span
        width 36px
        height 36px
        border-radius 50%
        padding-right 10px
      .problemIcon
        width 14px
        height 14px
        display flex
        justify-content center
        align-items center
        border 1px solid #ccc
        border-radius 50%
        background-color #fff
      img
        width 14px
        height 14px
        vertical-align middle
    .row
      display flex
      justify-content space-between
      align-items center
      .sum
        font-weight bold
        font-size: 24px;
      .icon-span
        width 133px
        height 70px
.demo-form-inline
  overflow hidden
  padding 22px 22px 0 22px
  margin 20px 0
  background: #fff
.table_header
  padding 30px 60px
  border 1px solid #ddd
.grid-content
  text-align center
  line-height 30px
.countTable
  padding-top 60px
  height 350px
.pagerWrapper1
  text-align right
  padding-top 10px
  font-size 12px
.tableWrapper {
  margin-top: 16px;
  position: relative;
  .switch {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 39px;
    cursor: pointer;
    img {
      height: 54px;
      width: 40px;
    }
  }
}
.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
    background: transparent;
    border-radius: 10px;
  }

  // 滚动条的滑块样式
  .tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(217, 225, 238, 1);
    border-radius: 10px;
  }
.tableWrapper >>> .el-table {
  width: 100%!important;
}
.tableWrapper >>> .headerCallStyle {
  height: 54px;
  font-size: 14px;
  background: #F3F7FF!important;
  padding: 5px;
  border-right: 0
  font-weight: 600
  color: rgba(0, 0, 0, 0.85)
}
.tableWrapper >>> .headerCellQ {
  background: #ffffff!important;
}
.tableWrapper >>> th.el-table__cell {
  border-right: 1px solid #CFDAEF;
}
.tableWrapper >>> .headerCellE {
  background: #ffffff!important;
}
.tableWrapper  >>> .headerSize {
  height: 30px;
  color: rgba(82, 96, 151, 1);
  font-size: 14px;
}
</style>
